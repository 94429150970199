@import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/

/*トップページ
-------------------------------------*/
#Page.pageIndex {
	#MainImg{
		background-color: #b50824;
		.contentBox01{
			&::after{
				background-image: url(../img/contents/top/00_mainimage.jpg);
			}
		}
	}
	#Main{
		.textTypePlayBall{
			line-height: 1;
			text-align: center;
			letter-spacing: .05em;
			color: #000;
			b{
				display: block;
				letter-spacing: 4px;
			}
		}
		#ContBox01{
			.listTypeStaff{
				position: relative;
				z-index: 1;
				&::after{
					content:"";
					display:block;
					clear:both;
				}
				li{
					float: left;
					a{
						display: block;
						position: relative;
						box-shadow: 0px 0px 30px 4px rgba(0,0,0,0.1);
						text-decoration: none;
						color: #000;
						&::before{
							content: "";
							position: absolute;
							top: 0;
							right: 0;
							display: block;
							width: 36px;
							height: 36px;
							background-repeat: no-repeat;
							background-position: center center;
							background-image: url(../img/contents/top/parts_03.png);
							background-size: 36px auto;
						}
						figure img{
							display: block;
							width: 100%;
							height: auto;
						}
						dl{
							position: relative;
							dt{
								display: table-cell;
								vertical-align: middle;
								text-align: center;
								letter-spacing: 2px;
								font-size: 16px;
							}
							dd{
								position: absolute;
								top: -10px;
								left: -5px;
								display: inline-block;
								padding: 4px 5px 0;
								height: 20px;
								line-height: 1;
								text-align: center;
								letter-spacing: 0.2em;
								font-size: 12px;
								color: #fff;
								&::after{
									content: "";
									position: absolute;
									bottom: -5px;
									left: 0;
									display: block;
									width: 5px;
									height: 5px;
									background-repeat: no-repeat;
									background-position: center center;
									background-size: 5px auto;
								}
							}
						}
					}
				}
			}
			#ContSubBox01{
				.listTypeStaff{
					li{
						dl{
							dt{
								background-color: #f4f9ff;
							}
							dd{
								background-color: #095eb5;
								&::after{
									background-image: url(../img/contents/top/parts_01.png);
								}
                                &.addColor{
                                    background-color: #2548b4;
                                    &::after{
                                        background-image: url(../img/contents/top/parts_05.png);
                                    }
                                }
							}
						}
					}
				}
				&> p a{
					font-size: 14px;
					color: #095eb5;
					&::before{
						content: "";
						position: relative;
						top: 1px;
						display: inline-block;
						margin-right: 10px;
						width: 15px;
						height: 11px;
						background-repeat: no-repeat;
						background-position: center center;
						background-image: url(../img/contents/icon_02.png);
						background-size: 15px auto;
					}
				}
			}
			#ContSubBox02{
				.listTypeStaff{
					li{
						dl{
							dt{
								background-color: #fff7fb;
							}
							dd{
								background-color: #b5075e;
								&::after{
									background-image: url(../img/contents/top/parts_02.png);
								}
							}
						}
					}
				}
			}
		}
		#ContBox02{
			#ContSubBox01{
				.contBox{
					h4{
						border-bottom-width: 1px;
						border-bottom-style: solid;
						text-align: center;
						letter-spacing: 2px;
						font-feature-settings: normal;
						-moz-font-feature-settings: normal;
						-webkit-font-feature-settings: normal;
						span{
							position: relative;
							display: inline-block;
							text-align: center;
							&::before{
								content: "";
								position: absolute;
								left: 50%;
								display: block;
								margin-left: -3.75px;
								border-radius: 50%;
								width: 3px;
								height: 3px;
							}
						}
					}
					.listTypeBlog{
						li{
						    overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							-webkit-text-overflow: ellipsis;
							font-family: helvetica,'arial black',arial,sans-serif;
							font-size: 16px;
							&:last-of-type{
								margin-bottom: 0;
							}
							dl{
								font-size: 0;
								dt,dd{
									display: inline-block;
									line-height: 1;
									text-align: center;
								}
								dt{
									font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
									font-weight: 500;
									color: #fff;
								}
								dd{
									font-family: 'Work Sans', sans-serif;
									font-weight: 400;
								}
							}
							a{
								text-decoration: none;
								font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
								font-weight: 500;
								color: #000;
							}
						}
					}
					&> a{
						position: relative;
						display: block;
						margin: 0 auto;
						padding-top: 11px;
						width: 180px;
						height: 40px;
						text-align: center;
						letter-spacing: .08em;
						text-decoration: none;
						font-family: 'Work Sans', sans-serif;
						font-size: 10px;
						font-weight: 600;
						color: #fff;
						letter-spacing: 0.24em;
						&::before{
							content: "";
							position: absolute;
							top: 50%;
							left: 0;
							width: 20px;
							height: 1px;
							background-color: #fff;
						}
						span{
							font-size: 12px;
						}
					}
				}
				.contBox01{
					h4{
						border-bottom-color: #095eb5;
						color: #095eb5;
						span::before{
							background-color: #095eb5;
						}
					}
					.listTypeBlog{
						li{
							dl dt{
								background-color: #095eb5;
							}
							dl dd{
								background-color: #eaf2fb;
								color: #095eb5;
							}
						}
					}
					&> a{
						background-color: #095eb5;
					}

				}
				.contBox02{
					h4{
						border-bottom-color: #b5075e;
						color: #b5075e;
						span::before{
							background-color: #b5075e;
						}
					}
					.listTypeBlog{
						li{
							dl dt{
								background-color: #b5075e;
							}
							dl dd{
								background-color: #fcf1f7;
								color: #b5075e;
							}
						}
					}
					&> a{
						background-color: #b5075e;
					}
				}
			}
		}
	}
	.modaal-wrapper{
		.modaal-container{
			box-shadow: none;
			.modaal-content-container{
				padding: 0;
				.box{
					padding: 0;
					figure{
						img{
							width: 100%;
							height: auto;
						}
						figcaption{
							letter-spacing: 2px;
							font-size: 20px;
							span{
								display: block;
								font-size: 16px;
							}
						}
					}
					.textBox{
						h4{
							margin-bottom: 34px;
							letter-spacing: 1px;
							font-size: 24px;
						}
						ul{
							li{
								&> dl{
									&> dt{
										padding-top: 7px;
										width: 71px;
										height: 26px;
										line-height: 1;
										text-align: center;
										font-size: 12px;
										color: #fff;
									}
									&> dt.textType01{
										letter-spacing: .035em;
									}
									&> dd{
										font-size: 16px;
										a[href$=".pdf"]{
											font-size: 14px;
											&::before{
												content: "";
												position: relative;
												top: 2px;
												left: 0;
												display: inline-block;
												margin-right: 10px;
												width: 16px;
												height: 15px;
												background-repeat: no-repeat;
												background-position: center center;
												background-image: url(../img/contents/icon_01.png);
												background-size: 16px auto;
											}
										}
									}
								}
							}
						}
}
				}
				.box01,.box02,.box07,.box08,.box09,.box11,.box12{
					figure{
						figcaption{
							span{
								color: #095eb5;
                                &.addColor{
                                    color: #2548b4;
                                }
							}
						}
					}
					.textBox{
						ul{
							li{
								&> dl{
									&> dt{
										background-color: #095eb5;
                                        &.addColor{
                                            background-color: #2548b4;
                                        }
									}
								}
							}
						}
					}
				}
				.box03,.box04,.box05,.box06{
					figure{
						figcaption{
							span{
								color: #b5075e;
							}
						}
					}
					.textBox{
						ul{
							li{
								&> dl{
									&> dt{
										background-color: #b5075e;
									}
									&> dd{
										a[href$=".pdf"]{
											color: #b5075e;
										}
									}
								}
							}
						}
					}
				}
			}
			.modaal-close{
				width: auto;
				height: auto;
				-webkit-transition: .4s;
				transition: .4s;
				&:hover,&:focus{
					opacity: 1;
					background-color: transparent;
				}
				span{
					position: relative !important;
					top: -41px;
					left: -69px;
					clip: auto !important;
					height:  auto !important;
					width: auto !important;
					overflow: auto;
				    letter-spacing: .18em;
					font-family: 'Work Sans', sans-serif;
					font-variant: small-caps;
					font-size: 14px;
					font-weight: 600;
					color: #fff;
				}
				&::before{
					position: static;
					box-sizing: border-box;
					border: 1px solid #fff;
					width: 60px;
					height: 60px;
					border-radius: 50%;
					background-repeat: no-repeat;
					background-position: center center;
					background-image: url(../img/contents/top/parts_04.png);
					background-size: 16px auto;
					background-color: transparent;
					-webkit-transition: 4s;
					transition: 4s;
					-webkit-transform: rotate(0);
					-ms-transform: rotate(0);
					transform: rotate(0);
				}
				&:after{
					content: none;
				}
			}
		}
	}
	.modaal-overlay{
		background-color: rgb(180, 180, 180) !important;
	}
}

@media print,screen and (min-width: 768px) {
	#Page.pageIndex {
		#MainImg{
			height: 790px;
			h2{
			}
			.contentBox01{
				width: 530px;
				height: 530px;
				&::before,&::after{
					width: 530px;
					height: 530px;
				}
			}
		}
		#Main{
			#ContBox01{
				position: relative;
				&::before,&::after{
					content: "";
					position: absolute;
					z-index: 0;
					display: block;
					width: calc(50% + 560px);
					min-width: 1000px;
					background-repeat: no-repeat;
					background-position: center center;
				}
				&::before{
					top: 416px;
					height: 493px;
                    background-color: #d9e7f5;
					background-position: right -1269px bottom;
					background-image: url(../img/contents/top/wall_01_02.png);
				}
				&::after{
					bottom: -60px;
                    right: 0;
					height: 280px;
                    background-color: #f9dbea;
					background-position: left -719px bottom;
					background-image: url(../img/contents/top/wall_02.png);
				}
				.innerBasic{
					padding-top: 143px;
					position: relative;
				}
				.textTypePlayBall{
					margin-bottom: 66px;
					padding-right: 38px;
					font-size: 100px;
					b{
						margin-top: 26px;
						margin-left: 30px;
						font-size: 20px;
					}
				}
				.listTypeStaff{
					position: relative;
					z-index: 1;
					float: left;
					width: 520px;
                    &:nth-of-type(2) {
						width: 480px;
					}
					&:last-of-type {
						width: 480px;
					}
					li{
						margin-right: 40px;
						width: 220px;
						background-color: #fff;
						&:last-of-type {
							margin-right: 0;
						}
						a{
							dl{
								dt{
									width: 220px;
									height: 60px;
								}
							}
							&:hover{
								opacity: .6;
								transition: .4s;
							}
						}
					}
				}
				.contSubBox::after{
					content:"";
					display:block;
					clear:both;
				}
				#ContSubBox01{
					height: 712px;
					.listTypeStaff{
						margin-bottom: 22px;
					}
					&> p {
						clear: both;
                        margin-bottom: -11px;
						padding-top: 6px;
						text-align: right;
						a:hover {
						opacity: .6;
						transition: .4s;
					  }
					}
				}
				#ContSubBox02{
                    clear: both;
                }
			}
			#ContBox02{
				.innerBasic{
					padding-top: 204px;
					padding-bottom: 205px;
				}
				.textTypePlayBall{
					margin-bottom: 54px;
					font-size: 60px;
					b{
						margin-top: 5px;
						font-size: 14px;
					}
				}
				#ContSubBox01{
					&::after{
						content:"";
						display:block;
						clear:both;
					}
					.contBox{
						width: 470px;
						h4{
							margin-bottom: 30px;
							padding-top: 10px;
							padding-bottom: 24px;
							font-size: 20px;
							letter-spacing: 0.2em;
							span{
								&::before{
									top: -4px;
								}
							}
						}
						.listTypeBlog{
							margin-bottom: 44px;
							li{
								margin-bottom: 24px;
								font-size: 16px;
								dl{
									margin-bottom: 12px;
									dt,dd{
										padding-right: 8px;
										padding-left: 10px;
										height: 26px;
										line-height: 26px;
									}
									dt{
										font-size: 12px;
										letter-spacing: 0.1em;
									}
									dd{
										padding-left: 8px;
										font-size: 14px;
										letter-spacing: 0.14em;
									}
								}
								a{
									letter-spacing: 0.1em;
									&:hover{
										opacity: .6;
										transition: .4s;
									}
								}
							}
						}
						&> a{
							&:hover{
								opacity: .6;
								transition: .4s;
							}
						}
					}
					.contBox01{
						float: left;
					}
					.contBox02{
						float: right;
					}
				}
			}
		}
		.modaal-wrapper{
			.modaal-inner-wrapper{
				padding-right: 0;
				padding-left: 40px;
			}
			.modaal-container{
				max-width: 960px;
				/*max-height: 530px;*/
				.modaal-content-container{
					.box{
						position: relative;
						figure{
							position: absolute;
							top: -38px;
							left: -40px;
							width: 220px;
							height: 220px;
							figcaption{
								margin-top: 26px;
								padding-left: 69px;
								span{
									margin-bottom: 3px;
								}
							}
						}
						.textBox{
							padding: 57px 30px 58px 210px;
							h4{
								margin-top: -6px;
							}
							ul{
								li{
									margin-bottom: 24px;
									&> dl{
										position: relative;
										padding-left: 86px;
										&> dt{
											position: absolute;
											top: 2px;
											left: 0;
										}
										&> dd{
											line-height: 32px;
											dl{
												dt{
													float: left;
													font-size: 14px;
												}
												dd{
													padding-left: 130px;
												}
											}
											a[href$=".pdf"]{
												&:hover{
													opacity: .6;
													text-decoration: none;
													transition: .4s;
												}
											}
										}
									}
								}
							}
}
					}
				}
				.modaal-close{
					&:hover{
						opacity: .6;
						transform: .4s;
						background-color: transparent;
					}
				}
			}
		}
		.modaal-close{
			top: 50px;
			right: 30px;
		}
	}
}

@media screen and (min-width: 768px) and (-ms-high-contrast: active), screen and (min-width: 768px) and (-ms-high-contrast: none) {
	#Page.pageIndex {
		#Main{
			#ContBox01{
				.listTypeStaff{
					li{
						a{
							dl{
								dd{
									padding-top: 6px;
								}
							}
						}
					}
				}
			}
		}
		.modaal-wrapper{
  			.modaal-close{
				overflow: visible;
			}
		}
	}

}

@media print,screen and (max-width: 767px) {
  /*
  トップページ
  -------------------------------------*/
	#Page.pageIndex {
		#MainImg{
			.contentBox01{
				&::after{
					padding-top: 100%;
				}
			}
		}
		#Main{
			#ContBox01{
				position: relative;
				.innerBasic{
					padding-top: 70px;
				}
				.textTypePlayBall{
					margin-right: 16px;
					margin-bottom: 60px;
					font-size: 50px;
					b{
						margin-top: 15px;
						margin-left: 10px;
						font-size: 14px;
					}
				}
				.listTypeStaff{
					li{
						width: 50%;
						&:nth-of-type(2n){
							padding-left: 7px;
						}
						&:nth-of-type(2n+1){
							padding-right: 7px;
						}
						a{
							dl{
								display: table;
								width: 100%;
								dt{
									height: 45px;
									width: 100%;
								}
							}
						}
					}
				}
				#ContSubBox01{
					margin-bottom: 62px;
					.listTypeStaff{
						margin-right: -15px;
						margin-left: -15px;
						padding-right: 15px;
						padding-bottom: 52px;
						padding-left: 15px;
						background-repeat: no-repeat, repeat-x;
						background-position: left bottom, left bottom;
						background-image: url(../img/contents/top/text_01.png), url(../img/contents/top/wall_01_sp.gif);
						background-size: 326px auto, 1px auto;
						&:first-of-type {
							margin-bottom: 46px;
						}
					}
					&> p {
						margin-top: 30px;
						margin-bottom: 0;
						text-align: right;
					}
				}
				#ContSubBox02{
					.listTypeStaff{
						margin-right: -15px;
						margin-left: -15px;
						padding-right: 15px;
						padding-bottom: 45px;
						padding-left: 15px;
						background-repeat: no-repeat, repeat-x;
						background-position: left bottom, left bottom;
						background-image: url(../img/contents/top/text_02.png), url(../img/contents/top/wall_02_sp.gif);
						background-size: 375px auto, 1px auto;
						&:first-of-type{
							margin-bottom: 55px;
						}
					}
				}
			}
			#ContBox02{
				position: relative;
				.innerBasic{
					padding-top: 72px;
					padding-bottom: 90px;
				}
				.textTypePlayBall{
					margin-bottom: 37px;
					font-size: 40px;
					b{
						margin-top: 2px;
						font-size: 12px;
					}
				}
				#ContSubBox01{
					.contBox{
						h4{
							margin-bottom: 30px;
							padding-top: 10px;
							padding-bottom: 24px;
							letter-spacing: 4px;
							font-size: 20px;
							span{
								&::before{
									top: -5px;
								}
							}
						}
						.listTypeBlog{
							margin-bottom: 25px;
							li{
								display: none;
								margin-bottom: 14px;
								font-size: 16px;
								&:nth-of-type(1),&:nth-of-type(2),&:nth-of-type(3){
									display: block;
								}
								dl{
									margin-bottom: 6px;
									dt,dd{
										padding-right: 5px;
										padding-left: 5px;
										height: 21px;
										line-height: 21px;
									}
									dt{
										font-size: 10px;
									}
									dd{
										font-size: 11px;
									}
								}
							}
						}
					}
					.contBox01{
						margin-bottom: 38px;
					}
				}
			}
		}
		.modaal-wrapper{
			top: 0;
			.modaal-inner-wrapper{
				padding: 90px 15px 30px;
				vertical-align: top;
			}
			.modaal-container{
				/*max-height: 433px;*/
				.modaal-content-container{
					.box{
						figure{
							margin-top: -60px;
							font-size: 0;
							img{
								position: absolute;
								top: -60px;
								left: 15px;
								width: 165px;
							}
							figcaption{
								display: table-cell;
								padding: 60px 15px 0 197px;
								height: 165px;
								vertical-align: middle;
								span{
									margin-bottom: 3px;
								}
							}
						}
						.textBox{
							overflow: auto;
							margin-top: 30px;
							margin-bottom: 30px;
							padding: 0 15px;
							max-height: 268px;
							max-height: calc(100vh - 285px);
							h4{
							}
							ul{
								li{
									margin-bottom: 24px;
									&> dl{
										&> dt{
											margin-bottom: 10px;
										}
										&> dd{
											line-height: 28px;
										}
									}
								}
							}
						}
					}
					.box01,.box02,.box07,.box08,.box09,.box11,.box12{
						.textBox{
							ul{
								li{
									&> dl{
										&> dd{
											dt{
												font-size: 14px;
												color: #095eb5;
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.modaal-close{
				top: 10px;
				right: 10px;
			}
		}
	}
}

/*
研究室からのお知らせ
-------------------------------------*/
#PageLaboratory {
	#MainImg {
		background-color: #095eb5;
		h3{
			span{
				background-color: #7bb8f6;
				color: #054e99;
			}
		}
	}
	#TopicPath {
  		a {
  			color: #095eb5;
  		}
  	}
	#ListBlock {
		li {
			a {
				text-decoration: none;
				dl {
					position: relative;
					dt {
						font-size: 20px;
						color: #000;
					}
					dd {
						line-height: 2;
						font-size: 16px;
						color: #888;
						span {
							text-decoration: underline;
							color: #095eb5;
						}
						&.detaBox {
							position: absolute;
							top: 0;
							font-size: 0;
							span {
								text-decoration: none;
							}
						}
					}
				}
				p.imageBox {
					float: left;
					img {
						width: 100%;
						height: auto;
					}
				}
				&:after {
			  content: "";
			  display: block;
			  clear: both;
			}
			}
			&:hover{
				opacity: 0.6;
				transition: .4s;
			}
		}
	}
	dd.detaBox,
	p.detaBox {
		b {
			background-color: #095eb5;
		}
		span {
			background-color: #eaf2fb;
			color: #095eb5;
			}
	}
  	#Container #Main .list_MoreBtn {
  		background-color: #095eb5;
  	}
}

#PageLaboratory.pageIndex{
	#MainImg {
		.contentBox01{
			&::after{
				background-image: url(../img/contents/laboratory/01_title.jpg);
			}
		}
	}
}

#PageLaboratory.pageEntry {
  	#Container {
  		#Main {
  			.pageMove {
  				border-top-color: #095eb5;
  				ul {
  					li {
  						&.top {
  							a {
  								color: #095eb5;
  							}
  						}
  						&.linkBtn {
  							a {
  								background-color: #095eb5;
  							}
  						}
  					}
  				}
  			}
  		}
  	}
}

@media print,screen and (min-width: 768px) {
  #PageLaboratory {
	#BlogNavi {
		border-bottom-color: #095eb5;
		ul {
			li {
				&.current-cat {
					a {
						background-image: url(../img/contents/laboratory/arrow_category.png);
						color: #095eb5;
					}
					&:after {
						background-color: #095eb5;
					}
				}
			}
		}
	}
  	#Container #Main {
  		padding-bottom: 155px;
		#ListBlock {
			li {
				margin-bottom: 83px;
				a {
					dl {
						float: right;
						width: 750px;
						padding-top: 40px;
						dt {
							margin-bottom: 10px;
						}
					}
					p.imageBox {
						width: 190px;
					}
				&:hover {
					span {
						text-decoration: none;
					}
				}
			}
			}
		}
  	}
  }

  /*ブログトップ
  -------------------------------------*/
  #PageLaboratory.pageIndex {
  }

  /*
  カテゴリアーカイブ
  -------------------------------------*/
  #PageLaboratory.pageCategory {
  }

  /*
  記事詳細ページ
  -------------------------------------*/
  #PageLaboratory.pageEntry {
  }

}

@media print,
screen and (max-width: 767px) {
	#PageLaboratory {
		#BlogNavi {
			ul {
				li {
					border-color: #095eb5;
					&.current-cat {
						a {
							color:#095eb5;
						}
						&:after {
							background-color: #095eb5;
						}
					}
				}
			}
		}
		#Container #Main {
			position: relative;
			padding-bottom: 120px;
			#ListBlock {
				li {
					margin-bottom: 51px;
					a {
						display: block;
						dl {
							padding-top: 35px;
							dt {
								margin-bottom: 10px;
								padding-left: 110px;
								text-align: justify;
								text-justify: inter-ideograph;
							}
							dd.detaBox{
								padding-left: 110px;
							}
							dd:not(.detaBox){
								clear: both;
								text-align: justify;
								text-justify: inter-ideograph;
							}
						}
						p.imageBox {
							width: 95px;
							margin-bottom: 10px;
						}
					}
				}
			}
		}
	}

   /*ブログトップ
  -------------------------------------*/
  #PageLaboratory.pageIndex {
  }

  /* カテゴリアーカイブ
  -------------------------------------*/
  #PageLaboratory.pageCategory {
  }

  /*
  記事詳細ページ
  -------------------------------------*/
	#PageLaboratory.pageEntry {
	}
}


/*
客員教授からのお知らせ
-------------------------------------*/
#PageVisiting_professor {
	#MainImg {
		background-color: #b5075e;
	}
	#TopicPath {
  		a {
  			color: #b5075e;
  		}
  	}
	#BlogNavi {
		ul {
			li {
				&.current-cat {
					a {
						color: #b5075e;
					}
				}
			}
		}
	}
	dd.detaBox,
	p.detaBox {
		b {
			background-color: #b5075e;
		}
		span {
			background-color: #fcf1f7;
			color: #b5075e;
			}
	}
	#ListBlock {
		padding-top: 30px;
		li {
			a {
				position: relative;
				display: block;
				border-color: #f0f0f0;
				border-style: solid;
				text-decoration: none;
				dl {
					position: relative;
					dt {
						font-size: 20px;
						color: #000;
					}
					dd {
						line-height: 2;
						font-size: 16px;
						color: #888;
						span {
							text-decoration: underline;
							color: #b5075e;
						}
						&.detaBox {
							position:absolute;
							font-size: 0;
							span {
								text-decoration: none;
							}
						}
					}
				}
				p.imageBox {
					z-index: 2;
					img {
						width: 100%;
						height: auto;
					}
				}
			}
			&:hover {
				opacity: 0.6;
				transition: .4s;
			}
		}
	}
	.list_MoreBtn {
		background-color: #b5075e;
	}
}

  #PageVisiting_professor.pageIndex {
	#MainImg {
		.contentBox01{
			&::after{
				background-image: url(../img/contents/visiting_professor/02_title.jpg);
			}
		}
	}
  }

  #PageVisiting_professor.pageEntry {
	  #Main{
		.pageMove {
			border-top-color: #b5075e;
			ul {
				li {
					&.top {
						a {
							color: #b5075e;
						}
					}
					&.linkBtn {
						a {
							background-color: #b5075e;
						}
					}
				}
			}
		}
	  }
  }

@media print,screen and (min-width: 768px) {
  #PageVisiting_professor {
  	#Container {
		#BlogNavi {
			border-bottom-color: #b5075e;
			ul {
				li {
					&.current-cat {
						a {
							background-image: url(../img/contents/visiting_professor/arrow_category.png);
						}
						&:after {
							background-color: #b5075e;
						}
					}
				}
			}
		}
  		#Main {
  			padding-bottom: 155px;
  			#ListBlock {
  				padding-top: 1px;
  				li {
  					margin-bottom: 150px;
					&:last-of-type{
						margin-bottom: 90px;
					}
  					a {
  						margin-top: 60px;
						margin-left: 60px;
						border-width: 8px;
						width: 940px;
  						min-height: 310px;
  						dl {
							padding:95px 60px 45px 431px;
  							dt {
  								margin-bottom: 10px;
  							}
  							dd {
  								&.detaBox {
  									top: 52px;
  								}
  							}
  						}
  						p.imageBox {
  							position: absolute;
  							top: -68px;
  							left: -68px;
							z-index: 2;
  							width:440px;
  							height:310px;
  							img {
  								width: 100%;
  								height: auto;
  							}
  						}
						&:hover {
							span {
								text-decoration: none;
							}
						}
  					}
  				}
  			}
  		}
  	}
  }

  /*ブログトップ
  -------------------------------------*/
  #PageVisiting_professor.pageIndex {
  }

  /* カテゴリアーカイブ
  -------------------------------------*/
  #PageVisiting_professor.pageCategory {
  }

  /*
  記事詳細ページ
  -------------------------------------*/
  #PageVisiting_professor.pageEntry {
  	#Container {
  		#Main {
  			#ContBox01 {
  				margin: 0 auto;
  				width: 750px;
  			}
  			h3 {
  				font-size: 28px;
  			}
  			.entryBox {
  				margin-top: 50px;
  				padding-bottom: 90px;
  			}
  		}
  	}
  }


}

@media print,
screen and (max-width: 767px) {
  #PageVisiting_professor {
  	#Container {
		#BlogNavi {
			ul {
				li {
					border-color: #b5075e;
					&.current-cat {
						&:after {
							background-color: #b5075e;
						}
					}
				}
			}
		}
  		#Main {
			position: relative;
  			padding-bottom: 155px;
  			#ListBlock {
  				li {
  					margin-bottom: 90px;
					&:last-of-type{
						margin-bottom: 60px;
					}
  					a {
						margin-left: 15px;
						border-width: 5px;
  						dl {
							padding: 50px 25px 32px;
  							dt {
  								margin-bottom: 10px;
  								text-align: justify;
  								text-justify: inter-ideograph;
  							}
  							dd {
  								&.detaBox {
  									top: 10px;
  								}
  							}
  						}
  						p.imageBox {
  							position: relative;
  							top: -35px;
  							left: -20px;
							width: 100%;
							width: -webkit-calc(100% + 5px) ;
  						}
  					}
  				}
  			}
  		}
  	}
  }


   /*ブログトップ
  -------------------------------------*/

  #PageVisiting_professor.pageIndex {
  }

  /* カテゴリアーカイブ
  -------------------------------------*/

  #PageVisiting_professor.pageCategory {
  }

  /*
  記事詳細ページ
  -------------------------------------*/

  #PageVisiting_professor.pageEntry {
  }


}